import _ from 'underscore';
import '../css/OtherAuthors.css';

function OtherAuthors(props) {
  
  console.log("Attempting to render Other Authors", props.authorData);
  const authorData = props.authorData;
  
  const ListElements = _.map(authorData, author => {
    
      const BookLinks = _.map(author.booklinks, book => {
        return (
          <div className="book-links">
            <h5>{book.title}</h5>
            <span onClick={() => window.open(book.amazonurl) }>See it on Amazon</span>
            <span onClick={() => window.open(book.goodreadsurl) }>Check it out on Goodreads</span>
          </div>
        )
      });
    
      const imageStyle = {
        backgroundImage: `url('/cover-images/${author.headshot}')`
      }
      console.log("Image styles? ", imageStyle);
      return (
      <div className="author-element-wrapper">
        <div className="headshot-image" style={ imageStyle }></div>
        <div className="author-info">
          <h2>{author.name}</h2>
          <p class="author-bio">{author.bio}</p>
          <div className="book-link-container">
            <h4>Works Include:</h4>
            {BookLinks}
          </div>
        </div>
      </div> )
  });
 
  return (
    <div className="OtherAuthors">
      <div className="authors-container">
        <h2>Other Amazing Indy Authors</h2>
        {ListElements}
      </div>
    </div>
  );
}

export default OtherAuthors;