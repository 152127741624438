import _ from 'underscore';
import '../css/BookList.css';

function BookList(props) {
  
  console.log("Attempting to render Book List", props.bookListData);
  const bookListData = props.bookListData;
  
  const ListElements = _.map(bookListData, book => {
      const imageStyle = {
        backgroundImage: `url('/cover-images/${book.coverimage}')`
      }
      console.log("Image styles? ", imageStyle);
      return (
      <div className="book-element-wrapper">
        <div className="cover-image" style={ imageStyle }></div>
        <div className="book-info">
          <h4>{book.title}</h4>
          <p>{book.blurb}</p>
          <div className="button-container">
            <button className="ebook" onClick={() => window.open(book.links.amazonebook) }>Buy e-Book</button>
            <button className="paperback" onClick={() => window.open(book.links.amazon) }>Buy paperback</button>
            <button className="goodreads" onClick={() => window.open(book.links.goodreads) }>View on Goodreads</button>
          </div>
        </div>
      </div> )
  });
 
  return (
    <div className="BookList">
      <div className="books-container">
        <h2>Books by Jason Bustard</h2>
        {ListElements}
      </div>
    </div>
  );
}

export default BookList;