import '../css/HeaderBox.css';

//let selectedTab = this.props.selectedTab;
//console.log("propscheck: ", selectedTab);

function HeaderBox(props) {
  console.log("Props in header", props);
  const isSelected = (tabName) => {
    if(props.selectedTab === tabName) {
      return 'selected';
    } else {
      return '';
    }
  }
  return (
    <div className="HeaderBox">
      <div className="qwertychimp-logo"></div>
      <div className="menu-container">
        <div onClick={() => props.updateSelectedTab('home') } className={`nav-button ${isSelected('home')}`}>
          <span>Home</span>
        </div>
        <div onClick={() => props.updateSelectedTab('news') } className={`nav-button news-pill mobile-only ${isSelected('news')}`}>
          <span>News</span>
        </div>
        <div onClick={() => props.updateSelectedTab('books') } className={`nav-button ${isSelected('books')}`}>
          <span>Books</span>
        </div>
        <div onClick={() => props.updateSelectedTab('stories') } className={`nav-button ${isSelected('stories')}`}>
          <span>Short Stories</span>
        </div>
        <div onClick={() => window.open('https://qwertychimp.blogspot.com/') } className={`nav-button ${isSelected('blog')}`}>
          <span>Musings</span>
        </div>
        <div onClick={() => window.open('https://www.midnighttidepublishing.com/') } className={`nav-button ${isSelected('mtp')}`}>
          <span>Midnight Tide Publishing</span>
        </div>
        <div onClick={() => props.updateSelectedTab('authors') } className={`nav-button ${isSelected('authors')}`}>
          <span>Other Authors</span>
        </div>
        <div onClick={() => props.updateSelectedTab('store') } className={`nav-button ${isSelected('store')}`}>
          <span>Store</span>
        </div>
      </div>
    </div>
  );
}

export default HeaderBox;
