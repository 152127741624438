import '../css/MTP.css';

function MTP(props) {
  
  console.log("Attempting to render Midnight Tide Info");
 
  return (
    <div className="MTP">
      <p>MIDNIGHT TIDE INFO HERE</p>
    </div>
  );
}

export default MTP;