import '../css/ViewBox.css';
import AuthorBio from '../components/AuthorBio';
import BookList from '../components/BookList';
import ShortStories from '../components/ShortStories';
import Blog from '../components/Blog';
import MTP from '../components/MTP';
import OtherAuthors from '../components/OtherAuthors';
import Store from '../components/Store';
import NewsContent from '../components/NewsContent';

function ViewBox(props) {
  let selectedTab = props.selectedTab;
  console.log("props in view: ", selectedTab);
  
  let selectedContent = <AuthorBio />;
  
  function changeSelectedContent(selection) {
    console.log("switching content: ", selection)
    switch(selection) {
      case 'home':
        selectedContent = <AuthorBio />;
      break;
      case 'news':
        selectedContent = <NewsContent newsFeed={props.newsFeed} />;
      break;
      case 'books':
        selectedContent = <BookList bookListData={props.bookListData} />;
      break;
      case 'stories':
        selectedContent = <ShortStories shortStoryData={props.shortStoryData} />;
      break;
      case 'blog':
        selectedContent = <Blog />;
      break;
      case 'mtp':
        selectedContent = <MTP />;
      break;
      case 'authors':
        selectedContent = <OtherAuthors authorData={props.authorData} />;
      break;
      case 'store':
        selectedContent = <Store />;
      break;
      default:
        selectedContent = <AuthorBio />;
      break;
    }
  }
  
  changeSelectedContent(selectedTab);
  
  return (
    <div className="ViewBox">
      <div className="content-container">
        <div className="news-container desktop-only">
          <h2>Recent News</h2>
          <div className="news-body">
            <NewsContent newsFeed={props.newsFeed} />
          </div>
        </div>
        <div className="dynamic-content">
          { selectedContent }
        </div>
      </div>
    </div>
  );
}

export default ViewBox;
