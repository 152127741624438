import '../css/Blog.css';

function Blog(props) {
  
  console.log("Attempting to render Blog");
 
  return (
    <div className="Blog">
      <p>BLOG</p>
    </div>
  );
}

export default Blog;