import { useState, useEffect } from 'react';
import './css/App.css';
import HeaderBox from './components/HeaderBox';
import ViewBox from './components/ViewBox';

function App() {
  
  const [selectedTab, setSelectedTab] = useState("home");
  const [bookListData, setBookListData] = useState({});
  const [newsFeed, setNewsFeed] = useState({});
  const [authorData, setAuthorData] = useState({});
  const [shortStoryData, setShortStoryData] = useState({});
  
  useEffect(() => {
    const goFetch = async (dataType) => {
      const dataLocation = "/data/" + dataType + ".json";
      const fetchedData = await fetch(dataLocation, {
                headers: {'content-type': "application/json"},
                mode: 'no-cors'
        }).then(function(response) {
                return response.json();
            }).then(function(json) {
                return json;
            });
            
      switch(dataType) {
        case 'booklist':
          setBookListData(fetchedData);
          break;
        case 'news':
          setNewsFeed(fetchedData);
          break;
        case 'authors':
          setAuthorData(fetchedData);
          break;
        case 'storylinks':
          setShortStoryData(fetchedData);
          break;
        default:
         console.log("NO DATA LOADED!");
      }
      
    };
    
    goFetch('booklist');
    goFetch('news');
    goFetch('authors');
    goFetch('storylinks');
  }, []);
  
  

  const updateSelectedTab = (newTab) => {
    console.log("updating tab", newTab);
     setSelectedTab(newTab);
  }
  
  return (
    <div className="App">
      <HeaderBox selectedTab={selectedTab} updateSelectedTab={updateSelectedTab} />
      <ViewBox selectedTab={selectedTab} bookListData={bookListData} newsFeed={newsFeed} authorData={authorData} shortStoryData={shortStoryData}/>
    </div>
  );
}

export default App;
