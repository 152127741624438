import '../css/Store.css';

function Store(props) {
  
  console.log("Attempting to render Store");
 
  return (
    <div className="Store">
      <p>COMING SOON(ish)</p>
    </div>
  );
}

export default Store;