import _ from 'underscore';
import '../css/ShortStories.css';

function ShortStories(props) {
  
  console.log("Attempting to render Short Stories: ", props.shortStoryData);
  
  const storyItems = _.map(props.shortStoryData, story => {
      
      return (
        <div className="story-link-wrapper">
          <h3 className="story-title">{story.title}</h3>
          <h5>{story.genres}</h5>
          <p className="story-blurb">{story.blurb}</p>
          <a className="story-link" href={story.link} target="_blank" rel="noreferrer">Read on Google Docs</a>
          <hr className="divider" />
        </div> )
  });
 
  return (
    <div className="ShortStories">
      <div><span className="copyright-message">All stories here are copyright of Jason Bustard (QwertyChimp LLC, author.qwerty.chimp@gmail.com) All rights reserved.</span></div>
      <div className="story-flex-grid">
        {storyItems}
      </div>
    </div>
  );
}

export default ShortStories;