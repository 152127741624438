import '../css/AuthorBio.css';

function AuthorBio(props) {
  
  console.log("Attmepting to render Author Bio");
 
  return (
    <div className="AuthorBio">
      <div className="bio-content">
        <div className="headshot-image"></div>
        <div className="bio-text">
            <h4>So why QwertyChimp?</h4>
            <p>There is a (sort of) famous saying used to describe infinity that
            goes something like: "If there were a room with an infinite number of
            chimpanzees banging on an infinite number of typewriters, 
            one will eventually write the collected works of Shakespeare on the 
            first try."</p>
            <p>As a writer, you often feel like one of those hapless hypothetical 
            chimps; banging away, but only making gibberish. But like those same 
            silly apes you keep on trying because, who knows, you could be the 
            next Willy Shakespeare.</p>
            <h4>So who is the chimp??</h4>
            <p>Jason Cyrus Bustard. Writer. Developer. Nerd. He grew up among the
            alfalfa fields of Central California and spent his formative years 
            lost in fantasy and sci-fi books, playing D&D (as the DM of course), 
            and falling in love with computers and video games. Today he lives in 
            wonderful, rainy, Northern Oregon with his wife, two kids, and a neurotic 
            bernadoodle.</p>
            <h4>Questions for the chimp?</h4>
            <p>email me at <b>author.qwerty.chimp@gmail.com</b></p>
        </div>
      </div>
    </div>
  );
}

export default AuthorBio;