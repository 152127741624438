import _ from 'underscore';
import '../css/NewsContent.css';

function NewsContent(props) {
  
  console.log("Attmepting to render News Content", props.newsFeed);
  
  const newsItems = _.map(props.newsFeed, newsItem => {
    
      const newsLinks = _.map(newsItem.links, link => {
        return (
          <div className="link-container">
            <span onClick={() => window.open(link.linkurl) }>{link.linkname}</span>
          </div>
          )
      });
      
      return (
        <div className="news-element-wrapper">
          <h3>{newsItem.headline}</h3>
          <h5>{newsItem.dateline}</h5>
          <p className="news-body">{newsItem.body}</p>
          {newsLinks}
        </div> )
  });
  
  
 
  return (
    <div className="NewsContent">
      {newsItems}
    </div>
  );
}

export default NewsContent;